'use strict' 

var script = document.createElement('script');
script.src = "https://storage.googleapis.com/track.salesflare.com/actual_flare_migrate.js";

var scriptElements = document.getElementsByTagName('script');
var loadScriptElement;
for(var i = 0; i < scriptElements.length; i++) {
    if (scriptElements[i].src.indexOf('flare_migrate.js') > -1) {
        loadScriptElement = scriptElements[i];
        break;
    }
}

if (!!loadScriptElement) {
    loadScriptElement.insertAdjacentElement('afterend', script);
}
else {
    document.head.appendChild(script);
}

function Flare(options) {

    var actualFlare;
    var flareOptions = options;
    var initialized = false;

    script.onload = function () {
        actualFlare = new ActualFlare(flareOptions);
        initialized = true;
    };

    this.track = function track(_trackingToken) {

        if (!initialized) {
            return retryIfNotInitialized(track, [_trackingToken]);
        }

        return actualFlare.track(_trackingToken);
    };

    this.identifyById = function identifyById(id, callback) {
        
        if (!initialized) {
            return retryIfNotInitialized(identifyById, [id, callback]);
        }
        return actualFlare.identifyById(id, callback);
    };

    this.trackPage = function trackPage() {
        
        if (!initialized) {
            return retryIfNotInitialized(trackPage);
        }
        return actualFlare.trackPage();
    };

    this.forward = function forward() {
        
        if (!initialized) {
            return retryIfNotInitialized(forward);
        }
        return actualFlare.forward();
    };
    
    this.identify = function identify(email, callback) {
        
        if (!initialized) {
            return retryIfNotInitialized(identify, [email, callback]);
        }
        return actualFlare.identify(email, callback);
    };

    function retryIfNotInitialized(func, params) {
        if (!initialized) {
            return setTimeout(() => retryIfNotInitialized(func, params));
        } 

        if (params) {
            return func(...params);
        }

        return func();
    }
}